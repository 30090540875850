import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import PoolImage from "../components/poolimage"


const Pool = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicPoolpicturet(sort: {fields: id}) {
        nodes {
          id
          data {
            picture {
              localFile {
                id
                childImageSharp {
                  id
                  fluid(maxWidth: 1024, quality: 75) {
                    ...GatsbyImageSharpFluid
                  }                  
                }
              }
            }
            picturetitle {
              text
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
        <div>
        {
            data.allPrismicPoolpicturet.nodes.map(drawData => {
                return (<div><PoolImage image={drawData.data.picture.localFile.childImageSharp.fluid} author={drawData.data.picturetitle.text} /></div>)
            })
        }
        </div>
    </Layout>
    );
}



export default Pool
