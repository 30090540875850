import React from "react"
import Img from "gatsby-image"


export default({image, author}) => {
            return (
                <div class="pool-block">
                    <div class="picture">
                        <Img durationFadeIn="10000" fluid={image} />
                    </div>

                    <div class="authortext">
                        {author}
                    </div>
                </div>                
            )
}
